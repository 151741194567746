<template>
  <base-layout-two
    page-title="Purchasable"
    page-default-back-link="/purchasables"
    end-button-text="Edit"
    :end-button-url="`/purchasables/${$route.params.id}/edit`"
  >
    <section class="ion-padding background-white">
      <h1>{{ purchasable.name }}</h1>
    </section>

    <div class="padding-8">
      <base-card title="Details">
        <base-field-display label-text="Description">
          <p>{{ purchasable.description }}</p>
        </base-field-display>

        <base-field-display label-text="Price Retail">
          <p>
            ${{
              purchasable.price_retail.toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            }}
          </p>
        </base-field-display>

        <base-field-display label-text="Tax Type">
          <p class="ion-text-capitalize">{{ purchasable.tax_type }}</p>
        </base-field-display>

        <base-field-display label-text="Property">
          <p>{{ purchasable.property.name }}</p>
        </base-field-display>
      </base-card>
    </div>
  </base-layout-two>
</template>

<script>
export default {
  data() {
    return {
      purchasable: {
        price_retail: 0,
        property: {},
      },
    };
  },

  ionViewWillEnter() {
    this.fetchRoom();
  },

  methods: {
    async fetchRoom() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/purchasables/${this.$route.params.id}`)
        .then((response) => {
          this.purchasable = response.data.purchasable;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>